/* @import must be at top of file, otherwise CSS will not work */
/* @import url("//hello.myfonts.net/count/352e7c");*/

@font-face {font-family: 'fabfeltscript-bold';src: url('./fabfelt/fabfeltscript-bold.eot');src: url('./fabfelt/fabfeltscript-bold.eot?#iefix') format('embedded-opentype'),url('./fabfelt/fabfeltscript-bold.woff2') format('woff2'),url('./fabfelt/fabfeltscript-bold.woff') format('woff'),url('./fabfelt/fabfeltscript-bold.ttf') format('truetype');}

@font-face {font-family: 'BrandonGrotesqueWeb-Bold';src: url('./brandonGrotesque/352E7C_1_0.eot');src: url('./brandonGrotesque/352E7C_1_0.eot?#iefix') format('embedded-opentype'),url('./brandonGrotesque/352E7C_1_0.woff2') format('woff2'),url('./brandonGrotesque/352E7C_1_0.woff') format('woff'),url('./brandonGrotesque/352E7C_1_0.ttf') format('truetype');}


@font-face {font-family: 'BrandonGrotesqueWeb-Regular';src: url('./brandonGrotesque/352E7C_2_0.eot');src: url('./brandonGrotesque/352E7C_2_0.eot?#iefix') format('embedded-opentype'),url('./brandonGrotesque/352E7C_2_0.woff2') format('woff2'),url('./brandonGrotesque/352E7C_2_0.woff') format('woff'),url('./brandonGrotesque/352E7C_2_0.ttf') format('truetype');}


@font-face {font-family: 'BrandonGrotesqueWeb-Italic';src: url('./brandonGrotesque/352E7C_3_0.eot');src: url('./brandonGrotesque/352E7C_3_0.eot?#iefix') format('embedded-opentype'),url('./brandonGrotesque/352E7C_3_0.woff2') format('woff2'),url('./brandonGrotesque/352E7C_3_0.woff') format('woff'),url('./brandonGrotesque/352E7C_3_0.ttf') format('truetype');}

body, p, div{
  font-family: 'BrandonGrotesqueWeb-Regular';
}
 h1, h2, h3, h4, h5{
   font-family: 'BrandonGrotesqueWeb-Regular'
 }
